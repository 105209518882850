import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Card from "../UI/Card"
import Button from "../UI/Button"
import ServiceList from "../Common/ServiceList"
import CtaBanner from "../Common/CtaBanner"
import HeroBgImg from "../../images/zenpharm-icon.svg"

Home.propTypes = {
	className: PropTypes.string.isRequired,
}

function Home({ className }) {

	return (
		<div className={className}>
			<Card accent className="hero">
				<div className="hero__content">
					<h1>Consultants to the pharmaceutical industry</h1>
					<p>We provide real help and practical solutions</p>
					<Button to="/contact-us">Contact us</Button>
					<Button className="is-secondary" to="/about-us">About us</Button>
				</div>
				<img className="hero__bg" src={HeroBgImg} alt="" />
			</Card>
			<ServiceList title="Our services include" />
			<CtaBanner />
		</div>
	)
}
export default styled(Home)`${({ theme })=>css`
	${Card}.hero {
		margin-top: 0;
		background-color: ${theme.c.darkTeal};
		position: relative;
		overflow: hidden;

		h1 {
			max-width: 628px;
			@media (min-width: ${theme.bp.lg}) {
				max-width: 756px;
			}
		}

		p {
			font-weight: 600;
			font-size: 20px;

			@media (min-width: ${theme.bp.md}) {
				font-size: 32px;
			}
		}

		${Button} {
			margin-right: 16px;

			@media (min-width: ${theme.bp.md}) {
				margin-right: 32px;
			}

			@media (min-width: ${theme.bp.lg}) {
				margin-right: 48px;
				width: 190px;
			}

			&.is-secondary {
				background: #006371;
				color: #fff;
			}
		}

		.hero__content {
			position: relative;
			z-index: 1;
		}
		
		.hero__bg {
			position: absolute;
			width: 240px;
			top: -32px;
			right: -40px;
			opacity: 0.5;
			z-index: 0;
			user-select: none;
			pointer-events: none;

			@media (min-width: ${theme.bp.md}) {
				width: 400px;
				opacity: 1;
			}

			@media (min-width: ${theme.bp.lg}) {
				width: 460px;
			}
		}
	}
`}`
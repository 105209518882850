import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import Card from "../../UI/Card"
import LinkCard from "../../UI/LinkCard"

ServiceList.propTypes = {
	className: PropTypes.string.isRequired,
}

function ServiceList({ className, title = "Other services" }) {

	return (
		<Card className={className}>
		<h2>{title}</h2>
		<div className="items">
			<LinkCard to="/services/qa-consultancy">QA Consultancy</LinkCard>
			<LinkCard to="/services/qp-and-rp-services">QP & RP Services</LinkCard>
			<LinkCard to="/services/auditing">Auditing</LinkCard>
			<LinkCard to="/services/licenses-and-regulatory">Licenses & Regulatory</LinkCard>
			<LinkCard to="/services/technical">Technical</LinkCard>
			<LinkCard to="/services/training">Training</LinkCard>
		</div>
		</Card>
	)
}
export default styled(ServiceList)`${({ theme })=>css`
	
	h2 {
		margin-top: 0;
	}
	.items {
		display: flex;
		flex-direction: column;
		margin-bottom: -8px;
		
		${LinkCard} {
			margin-bottom: 8px;

			&.is-active {
				display: none;
			}
		}

		@media (min-width: ${theme.bp.md}) {
			flex-direction: row;
			flex-wrap: wrap;
			margin-right: -16px;
			margin-bottom: -16px;

			${LinkCard} {
				flex-basis: calc(50% - 16px);
				margin-bottom: 16px;
				margin-right: 16px;
			}
		}

		@media (min-width: ${theme.bp.lg}) {
			margin-right: -32px;
			margin-bottom: -32px;

			${LinkCard} {
				flex-basis: calc(33% - 32px);
				margin-bottom: 32px;
				margin-right: 32px;
				font-size: 20px;
			}
		}
	}
`}`
import React from "react"
import Container from "../components/Common/Layout/Container"
import Header from "../components/Common/Header"
import Footer from "../components/Common/Footer"
import SEO from "../components/Common/Seo"
import Home from "../components/Home"
import Theme from "../components/Theme"

const IndexPage = () => (
  <Theme>
	<Header />
	<Container>
		<SEO title="Home" />
		<Home />
	</Container>
	<Footer />
  </Theme>
)

export default IndexPage
